import React from 'react';

import { Layout } from '../components/Layout';

const Work = () => (
  <Layout>
    <h1>Work</h1>
  </Layout>
);

export default Work;
